<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/product">Productos</router-link>
    </nav> -->
    <router-view/>
    <!-- //Todo: Footer -->
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
import Footer from './components/footer.vue'
export default {
  components: {
    Footer
  }
}
</script>
<style>
body{
  background-color: #aaaaaa78 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.bg-ema{
  background-color: #008f4d !important;
}
.paginate-links {
    width: 100%;
    list-style: none;
    text-align: center;
}

.paginate-links li {
    display: inline;
    background-color: #ffffff;
    color: #009a44;
    padding: 0.5rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    cursor: pointer;
    border-radius: 5rem;
    border-width: 1px;
    border-style: solid;
    border-color: #009a44;
}

.paginate-result {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
}


nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>
