import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VeeValidate from "vee-validate";
import VueAlertify from "vue-alertify";
import VuePaginate from "vue-paginate";
import VueBarcode from "vue-barcode";
import VueHtmlToPaper from "vue-html-to-paper";
import Vuelidate from "vuelidate";

Vue.use(Vuelidate);
Vue.use(VuePaginate);
Vue.use(VueBarcode);
Vue.use(VueAlertify);
Vue.use(VueHtmlToPaper);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VeeValidate);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
