import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },

  {
    path: "/inventario-gti",
    name: "inventario-GTI",
    component: () => import("../components/estructura/modulo-gti.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("access") == null) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/inventario-celulares",
    name: "inventario-finanzas",
    component: () => import("../views/finanzas.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("access") == null) {
        next("/");
      } else {
        next();
      }
    },
  },

  /* admin */
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin.vue"),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("access") == null) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/recuperacion/:uuid",
    name: "recuperacion_codigo",
    props: true,
    component: () => import("../views/recuperacion.vue"),
  },
  {
    path: "/restablecer-contrasena/solicitar",
    name: "solicitud_cambio",
    component: () => import("../views/send_email.vue"),
  },
  {
    path: "/inventario-cel",
    name: "inventario-cel",
    component: () => import("../views/admin/inventario-cel.vue"),
  },
  {
    path: "/inventarioGTI",
    name: "inventario-gti",
    component: () => import("../views/admin/inventario-gti.vue"),
  },
  // {
  //   path: '/recuperacion',
  //   name: 'recuperacion',
  //   component: () => import( '../views/recuperacion.vue'),

  // },
  // {
  //   path: "/inventarios",
  //   name: "ejecutivo",
  //   props: true,
  //   component: () => import("../views/admin/admin.vue"),
  // },

  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/Errors/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
export default router;
