<template>
    <div class="container-fluid">
        <Header></Header>
        <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center" style="height: 35rem;">
                <div class="card border-success border border-2 shadow-lg p-3 bg-body login-cont">

                    <div class="card-body text-success">
                        <div class="card-header bg-transparent">
                            <img src="../assets/emaLogin.svg" alt="logo" class="mb-2" width="100" height="80" />
                        </div>
                        <form @submit.prevent="create" method="post">
                            <b-form-group id="input-group-1" label="Nombre de usuario:" class="mt-3">
                                <b-form-input type="text" placeholder="Ingresa nombre de usuario"
                                    v-model="$v.username.$model"></b-form-input>
                            </b-form-group>
                            <!-- <p>{{ $v.email }}</p> -->
                            <!-- <p>{{ $v }}</p> -->
                            <small class="text-danger" v-if="!$v.username.required">
                                Campo requerido
                            </small>
                            <b-form-group id="input-group-2" label="Contraseña" class="mt-4">
                                <b-form-input type="password" placeholder="Contraseña de acceso"
                                    v-model="$v.password.$model">
                                </b-form-input>
                            </b-form-group>
                            <small class="text-danger" v-if="!$v.password.required">
                                Campo requerido
                            </small>
                            <b-button type="submit" class="px-3 py-2 w-100 mt-3 mb-2" variant="primary"
                                :disabled="$v.$invalid">Entrar</b-button>

                            <small>
                                <router-link :to="{ name: 'solicitud_cambio' }" style="text-decoration-line: none;">
                                    ¿Olvido tu contraseña?
                                </router-link>
                            </small>
                        </form>
                    </div>
                </div>
            </div>
            <br>

        </div>
        <div v-if="token == null">
            <router-link :to="{ name: 'login' }">

            </router-link>
        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>
<script>
import { required, minLength } from 'vuelidate/lib/validators'
import Header from '../components/header.vue';
import axios from 'axios';
import Footer from '../components/footer.vue'
import { getAPI } from '@/axios-api';
import VueJwtDecode from 'vue-jwt-decode'

export default {
    components: {
        Header,
        Footer
    },
    data() {
        return {
            username: '',
            password: '',
            error: false,
            error_msg: '',
            // token: localStorage.getItem('access') || null,
            token: '',
            usuario_id: '',
            gerencia_id: '',
            email_user_uno: process.env.VUE_APP_USER_S,
            email_user_dos: process.env.VUE_APP_USER_J,
            email_user_tres: process.env.VUE_APP_ADMIN
        }
    },
    methods: {
        create: function (e) {
            let json = {
                username: this.username,
                password: this.password
            };
            let usuario_id = ''
            getAPI.post('/api/auth/token', json)
                .then((response) => {
                    this.token = response.data.access;
                    const refresh = response.data.refresh;

                    localStorage.setItem('access', response.data.access)
                    localStorage.setItem('refresh', refresh)
                    // window.location.replace('inventario-GTI');
                    // this.$router.push('/inventario-GTI');
                    usuario_id = VueJwtDecode.decode(this.token).user_id


                    getAPI.post(`/api/permisos/usuario/${usuario_id}`)
                        .then((response) => {

                            this.gerencia_id = response.data.gerencia_id
                            localStorage.setItem('refresh', refresh)

                            if (this.gerencia_id == 1) {
                                this.$router.push('/inventario-gti');
                            }
                            else if (this.gerencia_id == 2) {
                                this.$router.push('/inventario-celulares');
                                /* this.$router.params.codigo; */
                            }
                            else if (this.gerencia_id == 17) {
                                this.$router.push('/admin')
                            }
                            else if (this.gerencia_id == 12) {
                                this.$router.push('/inventario-cel')
                            }
                        }).catch(err => {
                            console.log(err);
                            this.$alertify.error(err.response.data.error);

                        });

                }).catch(err => {
                    console.log(err);
                    this.$alertify.error("Correo o contraseña incorrectos");
                    localStorage.removeItem('access')
                });

        }
    },
    validations: {
        username: { required, minLength: minLength(5) },
        password: { required, minLength: minLength(5) }
    },
    mounted() {
        localStorage.removeItem('access')
        localStorage.removeItem('refresh')
    }
}
</script>
<style scoped>
.login-cont {
    max-width: 23rem;
    width: 100%;
    height: auto;
    margin-bottom: 4%;
    margin-top: 4%;
}
</style>